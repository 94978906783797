import {
  FILTERING,
  FILTERING_RESULT,
  SEARCHING,
  INIT_APP_LOAD_RESULT,
  SHOW_FILTER,
  INIT_APP_LOAD,
  DOWNLOAD_URL,
  RESET_FILTER,
  RESULT_FILTER_RESULT,
  SHOW_FILTER_SMALL_DEVICE,
} from "../@constants/Constants";

import {
  set_category_value,
  FilteringActionTypes,
  Incidents,
  operationProps,
  dateProps,
  storedDownloadUrl,
} from "../@constants/types";

export function ShowFilterNow(
  filter_view_status: string
): FilteringActionTypes {
  return {
    type: SHOW_FILTER,
    filter_view_status: filter_view_status,
  };
}

export function filterResult(
  incidents: Incidents,
  set_category_value: set_category_value,
  mtom: operationProps,
  mtoo: operationProps,
  mtot: operationProps,
  store_date: dateProps
): FilteringActionTypes {
  return {
    type: FILTERING_RESULT,
    incidents: incidents,
    set_category_value: set_category_value,
    mtom: mtom,
    mtoo: mtoo,
    mtot: mtot,
    store_date: store_date,
  };
}

export function activeSearching(
  data: string,
  incidents: Incidents
): FilteringActionTypes {
  return {
    type: SEARCHING,
    search: data,
    incidents: incidents,
  };
}

export function initAppLoad(incidents: Incidents): FilteringActionTypes {
  return {
    type: INIT_APP_LOAD,
    incidents: incidents,
  };
}

export function initAppLoadResult(
  mtom: operationProps,
  mtoo: operationProps,
  mtot: operationProps,
  store_date: dateProps,
  incidents: Incidents
): FilteringActionTypes {
  return {
    type: INIT_APP_LOAD_RESULT,
    incidents: incidents,
    store_date: store_date,
    mtom: mtom,
    mtoo: mtoo,
    mtot: mtot,
  };
}

export function activeFiltering(incidents: Incidents): FilteringActionTypes {
  return {
    type: FILTERING,
    incidents: incidents,
  };
}

export function setDownloadUrl(
  storedDownloadUrl: storedDownloadUrl
): FilteringActionTypes {
  return {
    type: DOWNLOAD_URL,
    storedDownloadUrl: storedDownloadUrl,
  };
}

export function resetFilter(): FilteringActionTypes {
  return {
    type: RESET_FILTER,
  };
}

export function resetAppResult(
  mtom: operationProps,
  mtoo: operationProps,
  mtot: operationProps,
  store_date: dateProps,
  incidents: Incidents
): FilteringActionTypes {
  return {
    type: RESULT_FILTER_RESULT,
    incidents: incidents,
    store_date: store_date,
    mtom: mtom,
    mtoo: mtoo,
    mtot: mtot,
  };
}

export function showFilterResultSmallDevice(): FilteringActionTypes {
  return {
    type: SHOW_FILTER_SMALL_DEVICE,
  };
}
