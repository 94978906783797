interface return_id {
  [key: number]: string[];
}

const queryGenerate = (return_id: return_id) => {
  try {
    let incidentType_Id = return_id[0];
    let area_Id = return_id[1];
    let system_Id = return_id[2];
    let triggering_reason_Id = return_id[3];
    let sub_triggering_reason_Id = return_id[4];
    let operation_Id = return_id[5];
    let sub_operation_Id = return_id[6];
    let equipment_Id = return_id[7];
    let storedDate_Id = return_id[8];
    let store_mto_t_ID = return_id[9];
    let store_mto_m_ID = return_id[10];
    let store_mto_o_ID = return_id[11];
    // let searchingItem = return_id[12];

    let query = `where: {
      _search: "" ,
      AND: [
        ${conditionCreator(incidentType_Id, "incidentType")}
        ${conditionCreator(area_Id, "areas_every")}
        ${conditionCreator(system_Id, "system")}
        ${conditionCreator(triggering_reason_Id, "triggeringReason_every")}
        ${conditionCreator(
          sub_triggering_reason_Id,
          "triggeringReasonCategory"
        )}
        ${conditionCreator(operation_Id, "operations_every")}
        ${conditionCreator(sub_operation_Id, "subOperation")} 
        ${conditionCreator(equipment_Id, "equipment")} 
        ${conditionCreator(storedDate_Id, "incidentDate_in")} 
        ${conditionCreator(store_mto_t_ID, "mtot")}  
        ${conditionCreator(store_mto_m_ID, "mtom")} 
        ${conditionCreator(store_mto_o_ID, "mtoo")}       
      ]}`;

    return query;
  } catch (err) {
    console.log("Error in queryGenerate ", err);
  }
};

const conditionCreator = (array_Id: string[], queryName: string) => {
  try {
    let return_data;
    if (queryName === "incidentDate_in") {
      return_data = `${
        array_Id.length !== 0
          ? "{ " +
            queryName +
            ": [" +
            array_Id.map((e) => {
              return '"' + e + '"';
            }) +
            "] },"
          : ""
      }`;
    } else if (
      queryName === "mtot" ||
      queryName === "mtom" ||
      queryName === "mtoo"
    ) {
      return_data = `${
        array_Id.length !== 0
          ? "{ " + queryName + ": " + array_Id[0] + " },"
          : ""
      }`;
    } else {
      return_data = `${
        array_Id.length !== 0
          ? "{ " +
            queryName +
            ": { id_in:[" +
            array_Id.map((e) => {
              return '"' + e + '"';
            }) +
            "]} },"
          : ""
      }`;
    }

    return return_data;
  } catch (err) {
    console.log("Error in conditionCreator ", err);
  }
};

export default queryGenerate;
