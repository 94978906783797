import { Incidents } from "../../@constants/types";
import { call, put } from "redux-saga/effects";
import { initAppLoadResult } from "../../@actions/SearchFilter";

import yearWiseArrayCreator from "./yearCreator";
import set_MTO_CategoryValue from "./mtOperationCreator";

interface actionProps {
  incidents: Incidents;
  type: string;
}

export const initialAppData = function* (action: actionProps): any {
  try {
    const mtom = yield call(set_MTO_CategoryValue, action.incidents, "mtom");
    const mtoo = yield call(set_MTO_CategoryValue, action.incidents, "mtoo");
    const mtot = yield call(set_MTO_CategoryValue, action.incidents, "mtot");

    const dateValue = yield call(yearWiseArrayCreator, action.incidents);

    yield put(initAppLoadResult(mtom, mtoo, mtot, dateValue, action.incidents));
  } catch (err) {
    console.log("Error is initialAppData", err);
  }
};
