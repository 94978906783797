import React, { memo, useState } from "react";
import { IoChevronForwardOutline } from "react-icons/io5";
import { Link } from "react-router-dom";
import Checkbox from "../../@library/checkBox/CheckBoxElement";
import HighlighterText from "../highlighterText/HighlighterText";
import "./Styles.css";

interface download_id {
  url: string;
}

interface FlatListProps {
  incidentNumber: string;
  incidentTitle: string;
  incidentDetails: string;
  Id: string;
  description: any;
  itemId: number;
  incidentCauses: any;
  downloadUrl: string[];
  downloadUrlAllData: Array<download_id>;
}

const FlatList: React.FC<FlatListProps> = memo(
  ({
    incidentNumber,
    incidentTitle,
    incidentDetails,
    Id,
    description,
    incidentCauses,
    itemId,
    downloadUrl,
    downloadUrlAllData,
  }) => {
    const [downloadLink] = useState(downloadUrlAllData);

    return (
      <div className="list-group customListGroup">
        <div className="list-group-item list-group-item-action customListGroup">
          <div className="d-flex">
            <div className="checkBoxDetails">
              <Checkbox id={Id} name={"download"} />
            </div>
            <div className="p-2">
              <Link
                to={{
                  pathname: `/describe/${Id}`,
                  state: {
                    fromDashboard: description,
                    ID: Id,
                    itemId: itemId,
                    incidentCauses: incidentCauses,
                    downloadUrl: downloadLink,
                  },
                }}
                className={"pluslink"}
              >
                {<HighlighterText text={incidentNumber} font={"bold"} />}
              </Link>
            </div>
            <div className="p-2">
              {/* <Text text={<HighlighterText text={incidentTitle} />} fontType="bold" /> */}
              <Link
                to={{
                  pathname: `/describe/${Id}`,
                  state: {
                    fromDashboard: description,
                    ID: Id,
                    itemId: itemId,
                    incidentCauses: incidentCauses,
                    downloadUrl: downloadLink,
                  },
                }}
                className={"pluslink"}
              >
                {<HighlighterText text={incidentTitle} font={"bold"} />}
              </Link>
            </div>
            <div className="ml-auto p-2">
              <Link
                to={{
                  pathname: `/describe/${Id}`,
                  state: {
                    fromDashboard: description,
                    ID: Id,
                    itemId: itemId,
                    incidentCauses: incidentCauses,
                    downloadUrl: downloadLink,
                  },
                }}
                className={"pluslink"}
              >
                <IoChevronForwardOutline />
              </Link>
            </div>
          </div>
          <p className="mb-1">
            <Link
              to={{
                pathname: `/describe/${Id}`,
                state: {
                  fromDashboard: description,
                  ID: Id,
                  itemId: itemId,
                  incidentCauses: incidentCauses,
                  downloadUrl: downloadLink,
                },
              }}
              className={"pluslink"}
            >
              {<HighlighterText text={incidentDetails} font={"regular"} />}{" "}
            </Link>
          </p>
        </div>
        {/* </Link> */}
      </div>
    );
  }
);

export default FlatList;
