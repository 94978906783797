import React, { memo, useCallback, useEffect, useState } from "react";
import "./Styles.css";
import { useLazyQuery } from "@apollo/client";
import { useSelector, useDispatch } from "react-redux";
import { activeFiltering } from "../../@actions/SearchFilter";

import { setFilterId } from "../../@actions/FilterIdStore";

import { storeIdGenerate } from "./Methods";
import { gql } from "@apollo/client";

import { IncidentsFragment } from "../../@services/SearchAndFilterQuery";

import queryGenerate from "../../@services/QueryGenerate";

interface CheckBoxProps {
  id: any;
  category: string;
}

const Checkbox: React.FC<CheckBoxProps> = memo(({ id, category }) => {
  const dispatch = useDispatch();

  const searchingItem = useSelector((state: any) => state.FILTER.searchingItem);

  const [item_id] = useState(id);

  // Store ID

  const store_incidentTypeId = useSelector(
    (state: any) => state.FILTER.store_incidentTypeId
  );
  const store_areaId = useSelector((state: any) => state.FILTER.store_areaId);
  const store_systemId = useSelector(
    (state: any) => state.FILTER.store_systemId
  );
  const store_triggering_reasonId = useSelector(
    (state: any) => state.FILTER.store_triggering_reasonId
  );
  const store_sub_triggering_reasonId = useSelector(
    (state: any) => state.FILTER.store_sub_triggering_reasonId
  );
  const store_operationId = useSelector(
    (state: any) => state.FILTER.store_operationId
  );
  const store_sub_operationId = useSelector(
    (state: any) => state.FILTER.store_sub_operationId
  );
  const store_equipmentId = useSelector(
    (state: any) => state.FILTER.store_equipmentId
  );

  const storedDate = useSelector((state: any) => state.FILTER.storedDate);

  const store_mto_t = useSelector((state: any) => state.FILTER.store_mto_t);

  const store_mto_m = useSelector((state: any) => state.FILTER.store_mto_m);

  const store_mto_o = useSelector((state: any) => state.FILTER.store_mto_o);

  const reset_filter = useSelector((state: any) => state.FILTER.reset_filter);

  // waste code

  const load_query_status = useSelector(
    (state: any) => state.FILTER.load_query_status
  );
  const [Check, setCheck] = useState(false);
  const [queryCondition, setQueryCondition] = useState("");

  const MyQuery = gql`
    query MyQuery {
      en: incidents(locales: en ${queryCondition}) {
        ...IncidentsFragment
        attachmentNo{
          url
        }
        attachmentEn{
          url
        }
      }
      nb: incidents(locales: nb ${queryCondition}) {
        ...IncidentsFragment
        attachmentNo{
          url
        }
        attachmentEn{
          url
        }
      }
    }
    ${IncidentsFragment}
  `;

  useEffect(() => {
    if (reset_filter === true) {
      setCheck(!reset_filter);
    }

    if (store_systemId.includes(item_id)) {
      setCheck(true);
    }
    if (store_areaId.includes(item_id)) {
      setCheck(true);
    }
    if (store_operationId.includes(item_id)) {
      setCheck(true);
    }
    if (store_sub_operationId.includes(item_id)) {
      setCheck(true);
    }
    if (store_sub_triggering_reasonId.includes(item_id)) {
      setCheck(true);
    }
    if (store_triggering_reasonId.includes(item_id)) {
      setCheck(true);
    }
    if (store_incidentTypeId.includes(item_id)) {
      setCheck(true);
    }
    if (store_equipmentId.includes(store_equipmentId)) {
      setCheck(true);
    }
    if (store_systemId.includes(item_id)) {
      setCheck(true);
    }

    if (category === "mto-m" && store_mto_m.includes(item_id)) {
      setCheck(true);
    }

    if (category === "mto-t" && store_mto_t.includes(item_id)) {
      setCheck(true);
    }

    if (category === "mto-o" && store_mto_o.includes(item_id)) {
      setCheck(true);
    }

    if (category === "Years" && storedDate.length !== 0) {
      let checkDate = item_id.every((e: string) => {
        if (storedDate.includes(e) === true) {
          return true;
        } else {
          return false;
        }
      });

      if (checkDate === true) {
        setCheck(true);
      }
    }
  }, [
    category,
    reset_filter,
    store_systemId,
    item_id,
    store_areaId,
    store_operationId,
    store_sub_operationId,
    store_sub_triggering_reasonId,
    store_triggering_reasonId,
    store_incidentTypeId,
    store_equipmentId,
    store_mto_m,
    store_mto_o,
    store_mto_t,
    storedDate,
  ]);

  const [loadGreetingData, { loading, data }] = useLazyQuery(MyQuery, {
    fetchPolicy: "no-cache",
  });
  useEffect(() => {
    if (data !== undefined && loading === false) {
      dispatch(activeFiltering(data));
    }
  }, [data, loading, dispatch]);

  const onClicked = useCallback(
    (e) => {
      if (load_query_status === true) {
        let return_id = storeIdGenerate(
          store_incidentTypeId,
          store_areaId,
          store_systemId,
          store_triggering_reasonId,
          store_sub_triggering_reasonId,
          store_operationId,
          store_sub_operationId,
          store_equipmentId,
          storedDate,
          store_mto_t,
          store_mto_m,
          store_mto_o,
          searchingItem,
          category,
          item_id,
          Check
        );

        let incidentType_Id = return_id[0];
        let area_Id = return_id[1];
        let system_Id = return_id[2];
        let triggering_reason_Id = return_id[3];
        let sub_triggering_reason_Id = return_id[4];
        let operation_Id = return_id[5];
        let sub_operation_Id = return_id[6];
        let equipment_Id = return_id[7];
        let storedDate_Id = return_id[8];
        let store_mto_t_ID = return_id[9];
        let store_mto_m_ID = return_id[10];
        let store_mto_o_ID = return_id[11];

        dispatch(
          setFilterId(
            incidentType_Id,
            area_Id,
            system_Id,
            triggering_reason_Id,
            sub_triggering_reason_Id,
            operation_Id,
            sub_operation_Id,
            equipment_Id,
            storedDate_Id,
            store_mto_t_ID,
            store_mto_m_ID,
            store_mto_o_ID
          )
        );

        setCheck(!Check);

        let query_is: any = queryGenerate(return_id);

        setQueryCondition(query_is);

        loadGreetingData();
      }
    },
    [
      setQueryCondition,
      dispatch,
      storedDate,
      store_mto_t,
      store_mto_m,
      store_mto_o,
      Check,
      item_id,
      store_incidentTypeId,
      store_areaId,
      store_systemId,
      store_triggering_reasonId,
      store_sub_triggering_reasonId,
      store_operationId,
      store_sub_operationId,
      store_equipmentId,
      loadGreetingData,
      category,
      searchingItem,
      load_query_status,
    ]
  );

  useEffect(() => {
    if (category === "mto-t" && store_mto_t[0] !== undefined) {
      if (store_mto_t[0] !== item_id) setCheck(false);
    }

    if (category === "mto-m" && store_mto_m[0] !== undefined) {
      if (store_mto_m[0] !== item_id) setCheck(false);
    }

    if (category === "mto-o" && store_mto_o[0] !== undefined) {
      if (store_mto_o[0] !== item_id) setCheck(false);
    }
  }, [category, item_id, store_mto_t, store_mto_m, store_mto_o]);

  return (
    <>
      <div className="grid">
        <label className="checkbox path">
          <input
            type="checkbox"
            checked={Check}
            //defaultChecked={check}
            onChange={onClicked}
          />
          <svg viewBox="0 0 21 21">
            <path d="M5,10.75 L8.5,14.25 L19.4,2.3 C18.8333333,1.43333333 18.0333333,1 17,1 L4,1 C2.35,1 1,2.35 1,4 L1,17 C1,18.65 2.35,20 4,20 L17,20 C18.65,20 20,18.65 20,17 L20,7.99769186"></path>
          </svg>
        </label>
      </div>
    </>
  );
});

export default Checkbox;
