import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Header from "../../@component/header/Header";
import Body from "../../@component/body/Body";
import { initAppLoad } from "../../@actions/SearchFilter";
import MyQuery from "../../@services/IncidensQuery";
import { useQuery } from "@apollo/client";

import LoaderSpinner from "../../@library/loader/LoaderSpinner";

const Describe: React.FC<any> = (props) => {
  const { data, error, loading } = useQuery(MyQuery, {
    fetchPolicy: "no-cache",
  });

  const dispatch = useDispatch();

  const lang = useSelector((state: any) => state.LANG.lang);
  const load_query_status = useSelector(
    (state: any) => state.FILTER.load_query_status
  );
  const incidentsStored = useSelector(
    (state: any) => state.FILTER.incidentsStored
  );

  const { id }: any = useParams();

  const [downloadUrl, setDownloadUrl] = useState("");

  const [loader, setLoader] = useState(true);
  const [title, setTitle] = useState("");
  const [subtitle, setSubtitle] = useState("");
  const [system, setSystem] = useState("");
  const [operation, setOperation] = useState("");
  const [areas, setArea] = useState("");
  const [triggeringReasons, setTriggeringReason] = useState("");
  const [description, setDescription] = useState("");
  const [learningPoints, setLearningPoints] = useState("");
  const [incidentCauses, setIncidentCauses] = useState("");

  useEffect(() => {
    if (
      load_query_status === false &&
      loading === false &&
      data !== undefined &&
      error === undefined
    ) {
      dispatch(initAppLoad(data));
    } else {
      if (incidentsStored.length !== 0) {
        // for manually download url problem
        incidentsStored["en"].map((e: any) => {
          if (e.id === id) {
            let DownLoadUrl =
              lang === "en" ? e.attachmentEn[0].url : e.attachmentNo[0].url;
            setDownloadUrl(DownLoadUrl.toString());
          }

          return e;
        });

        incidentsStored[lang].map((e: any) => {
          if (e.id === id) {
            let title = e.title;
            let subtitle = `${e.incidentNumber} - ${e.incidentType.title}`;
            let system = e.system.title;
            let operation = e.operations[0].title;
            let area = e.areas[0].title;
            let triggeringReason = e.triggeringReason[0].title;

            setTitle(title);
            setSubtitle(subtitle);
            setOperation(operation);
            setTriggeringReason(triggeringReason);
            setArea(area);
            setDescription(e.description.html);
            setLearningPoints(e.learningPoints.html);
            setIncidentCauses(e.incidentCauses.html);
            if (system !== null) setSystem(system);
            setLoader(false);
          }

          return e;
        });
      }
    }
  }, [
    id,
    incidentsStored,
    load_query_status,
    loading,
    error,
    data,
    lang,
    dispatch,
  ]);

  return (
    <>
      <Header
        moduleName="describe"
        mainTitle={title}
        subTitle={subtitle}
        systems={system}
        operations={operation}
        area={areas}
        triggeringReason={triggeringReasons}
        downloadUrl={downloadUrl}
      />
      {loader === true ? (
        <LoaderSpinner />
      ) : (
        <Body
          data={description}
          learningPoints={learningPoints}
          incidentCauses={incidentCauses}
          moduleName="describe"
        />
      )}
    </>
  );
};

export default Describe;
