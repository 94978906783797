import React, { Suspense } from "react";
import { render } from "react-dom";

import reportWebVitals from "./reportWebVitals";
import App from "./App";
import { ApolloProvider } from "@apollo/client";

import { ApolloClient, InMemoryCache } from "@apollo/client";
import "bootstrap/dist/css/bootstrap.min.css";

import "./i18n";

const client = new ApolloClient({
  uri:
    "https://api-eu-central-1.graphcms.com/v2/ckhq0d1g4k1yf01z0b1htc5ce/master",
  cache: new InMemoryCache(),
});

function Index() {
  return (
    <ApolloProvider client={client}>
      <React.StrictMode>
        <Suspense fallback={<div>Loading.......</div>}>
          <App />
        </Suspense>
      </React.StrictMode>
    </ApolloProvider>
  );
}

render(<Index />, document.getElementById("root"));

reportWebVitals();
