export const storeIdGenerate = (
  store_incidentTypeId: string[],
  store_areaId: string[],
  store_systemId: string[],
  store_triggering_reasonId: string[],
  store_sub_triggering_reasonId: string[],
  store_operationId: string[],
  store_sub_operationId: string[],
  store_equipmentId: string[],
  storedDate: string[],
  store_mto_t: string[],
  store_mto_m: string[],
  store_mto_o: string[],
  searchingItem: string[],
  category: string,
  itemID: any,
  Check: boolean
) => {
  if (category === "search") {
    searchingItem = [itemID];
  }

  if (category === "mto-t") {
    if (Check === false) {
      store_mto_t = [itemID];
    } else {
      store_mto_t = store_mto_t.filter((e) => e !== itemID);
    }
  }

  if (category === "mto-m") {
    if (Check === false) {
      store_mto_m = [itemID];
    } else {
      store_mto_m = store_mto_m.filter((e) => e !== itemID);
    }
  }

  if (category === "mto-o") {
    if (Check === false) {
      store_mto_o = [itemID];
    } else {
      store_mto_o = store_mto_o.filter((e) => e !== itemID);
    }
  }

  if (category === "Years") {
    // Check === false
    //   ? (storedDate = [...storedDate, ...itemID])
    //   : (storedDate = storedDate.filter((e) => e !== itemID));

    if (Check === false) {
      storedDate = [...storedDate, ...itemID];
    } else {
      storedDate = storedDate.filter((e) => itemID.includes(e) !== true);
    }
  }

  if (category === "IncidentTypes") {
    Check === false
      ? store_incidentTypeId.push(itemID)
      : (store_incidentTypeId = store_incidentTypeId.filter(
          (e) => e !== itemID
        ));
  }

  if (category === "Systems") {
    Check === false
      ? store_systemId.push(itemID)
      : (store_systemId = store_systemId.filter((e) => e !== itemID));
  }

  if (category === "Areas") {
    Check === false
      ? store_areaId.push(itemID)
      : (store_areaId = store_areaId.filter((e) => e !== itemID));
  }
  if (category === "TriggeringReasons") {
    Check === false
      ? store_triggering_reasonId.push(itemID)
      : (store_triggering_reasonId = store_triggering_reasonId.filter(
          (e) => e !== itemID
        ));
  }
  if (category === "SubTriggeringReason") {
    Check === false
      ? store_sub_triggering_reasonId.push(itemID)
      : (store_sub_triggering_reasonId = store_sub_triggering_reasonId.filter(
          (e) => e !== itemID
        ));
  }
  if (category === "Operations") {
    Check === false
      ? store_operationId.push(itemID)
      : (store_operationId = store_operationId.filter((e) => e !== itemID));
  }
  if (category === "SubOperations") {
    Check === false
      ? store_sub_operationId.push(itemID)
      : (store_sub_operationId = store_sub_operationId.filter(
          (e) => e !== itemID
        ));
  }
  if (category === "Equipments") {
    Check === false
      ? store_equipmentId.push(itemID)
      : (store_equipmentId = store_equipmentId.filter((e) => e !== itemID));
  }

  return [
    store_incidentTypeId,
    store_areaId,
    store_systemId,
    store_triggering_reasonId,
    store_sub_triggering_reasonId,
    store_operationId,
    store_sub_operationId,
    store_equipmentId,
    storedDate,
    store_mto_t,
    store_mto_m,
    store_mto_o,
    searchingItem,
  ];
};
