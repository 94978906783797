import { gql } from "@apollo/client";

const MyQuery = gql`
  query MyQuery {
    en: incidents(locales: en) {
      id
      title
      mtot
      mtoo
      mtom
      ingress {
        text
      }
      description {
        html
        text
      }
      incidentType {
        title
      }
      areas {
        title
      }
      system {
        title
      }
      operations {
        title
      }
      triggeringReason {
        title
      }
      incidentCauses {
        html
        text
      }
      learningPoints {
        html
        text
      }
      incidentNumber
      incidentDate
      triggeringReasonCategory {
        title
        id
      }
      subOperation {
        title
        id
      }
      equipment {
        equipmentInvolved
        id
      }

      attachmentNo {
        url
      }
      attachmentEn {
        url
      }
    }

    nb: incidents(locales: nb) {
      id
      title
      mtot
      mtoo
      mtom
      ingress {
        text
      }
      description {
        html
        text
      }
      incidentType {
        title
      }
      areas {
        title
      }
      system {
        title
      }
      operations {
        title
      }
      triggeringReason {
        title
      }
      incidentCauses {
        html
      }
      learningPoints {
        html
      }
      incidentNumber
      incidentDate
      triggeringReasonCategory {
        title
        id
      }
      subOperation {
        title
        id
      }
      equipment {
        equipmentInvolved
        id
      }
      attachmentNo {
        url
      }
      attachmentEn {
        url
      }
    }
  }
`;

export default MyQuery;
