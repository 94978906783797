import React from "react";
import { Col, Row } from "react-bootstrap";
import { Container } from "react-bootstrap";
import "./Styles.css";

const Describe: React.FC<any> = ({ info, incidentCauses, learningPoints }) => {
  return (
    <Container fluid>
      <Row>
        <Col>
          <div
            className="describeHtml"
            dangerouslySetInnerHTML={{
              __html: info,
            }}
          ></div>
          <div
            className="describeHtml"
            dangerouslySetInnerHTML={{
              __html: incidentCauses,
            }}
          ></div>
          <div
            className="describeHtml"
            dangerouslySetInnerHTML={{
              __html: learningPoints,
            }}
          ></div>
        </Col>
      </Row>
    </Container>
  );
};

export default Describe;
