import { CHANGED_LANGUAGE } from "../@constants/Constants";

import { GetLanguageStateType, LangActionTypes } from "../@constants/types";

const initialStateGetPosts: GetLanguageStateType = {
  lang: "en", //[],
};

const getLangReducer = (
  state = initialStateGetPosts,
  action: LangActionTypes
): GetLanguageStateType => {
  switch (action.type) {
    case CHANGED_LANGUAGE:
      return {
        ...state,
        lang: action.lang,
      };
    default:
      return state;
  }
};

export default getLangReducer;
