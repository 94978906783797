import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Chart from "../chart/Chart";
import Describe from "../describe/Describe";
import DetailsTitleBar from "../detailsTitleBar/DetailsTitleBar";
import SystemFilter from "../systemFilter/SystemFilter";
import "./Styles.css";
import { useQuery } from "@apollo/client";

import {
  IncidentTypesQuery,
  SystemQuery,
  AreasQuery,
  TriggeringReasonsQuery,
  OperationsQuery,
  Operation2ndlevelQuery,
  equipmentInvolvedQuery,
  triggeringReasonCategoriesQuery,
} from "../../@services/SystemQuery";

interface BodyProps {
  data: any;
  incidentCauses: any;
  learningPoints: any;
  moduleName: string;
}

const Body: React.FC<BodyProps> = ({
  data,
  moduleName,
  incidentCauses,
  learningPoints,
}) => {
  var IncidentTypes: any = [];
  var Systems: any = [];
  var Areas: any = [];
  var TriggeringReasons: any = [];
  var Operations: any = [];
  var Operation2ndlevel: any = [];
  var equipmentInvolved: any = [];
  var TriggeringReasonCategories: any = [];

  const { loading, error, data: dataEX } = useQuery(IncidentTypesQuery, {
    fetchPolicy: "no-cache",
  });

  const {
    loading: loadingTriggeringReasonCategoriesQuery,
    error: errorTriggeringReasonCategoriesQuery,
    data: dataTriggeringReasonCategoriesQuery,
  } = useQuery(triggeringReasonCategoriesQuery, {
    fetchPolicy: "no-cache",
  });

  const {
    loading: loadingEquipmentInvolvedQuery,
    error: errorEquipmentInvolvedQuery,
    data: dataEquipmentInvolvedQuery,
  } = useQuery(equipmentInvolvedQuery, {
    fetchPolicy: "no-cache",
  });

  const {
    loading: loadingSystem,
    error: errorSystem,
    data: dataSystem,
  } = useQuery(SystemQuery, {
    fetchPolicy: "no-cache",
  });

  const {
    loading: loadingAreasQuery,
    error: errorAreasQuery,
    data: dataAreasQuery,
  } = useQuery(AreasQuery, {
    fetchPolicy: "no-cache",
  });

  const {
    loading: loadingTriggeringReasonsQuery,
    error: errorTriggeringReasonsQuery,
    data: dataTriggeringReasonsQuery,
  } = useQuery(TriggeringReasonsQuery, {
    fetchPolicy: "no-cache",
  });

  const {
    loading: loadingOperationsQuery,
    error: errorOperationsQueryQuery,
    data: dataOperationsQueryQuery,
  } = useQuery(OperationsQuery, {
    fetchPolicy: "no-cache",
  });

  const {
    loading: loadingOperation2ndlevelQuery,
    error: errorOperation2ndlevelQuery,
    data: dataOperation2ndlevelQuery,
  } = useQuery(Operation2ndlevelQuery, {
    fetchPolicy: "no-cache",
  });

  if (loading === false && dataEX !== undefined && error === undefined)
    IncidentTypes = dataEX;
  if (
    loadingSystem === false &&
    dataSystem !== undefined &&
    errorSystem === undefined
  )
    Systems = dataSystem;
  if (
    loadingAreasQuery === false &&
    dataAreasQuery !== undefined &&
    errorAreasQuery === undefined
  )
    Areas = dataAreasQuery;
  if (
    loadingTriggeringReasonsQuery === false &&
    dataTriggeringReasonsQuery !== undefined &&
    errorTriggeringReasonsQuery === undefined
  )
    TriggeringReasons = dataTriggeringReasonsQuery;
  if (
    loadingOperationsQuery === false &&
    dataOperationsQueryQuery !== undefined &&
    errorOperationsQueryQuery === undefined
  )
    Operations = dataOperationsQueryQuery;
  if (
    loadingOperation2ndlevelQuery === false &&
    dataOperation2ndlevelQuery !== undefined &&
    errorOperation2ndlevelQuery === undefined
  )
    Operation2ndlevel = dataOperation2ndlevelQuery;
  if (
    loadingEquipmentInvolvedQuery === false &&
    dataEquipmentInvolvedQuery !== undefined &&
    errorEquipmentInvolvedQuery === undefined
  )
    equipmentInvolved = dataEquipmentInvolvedQuery;
  if (
    loadingTriggeringReasonCategoriesQuery === false &&
    dataTriggeringReasonCategoriesQuery !== undefined &&
    errorTriggeringReasonCategoriesQuery === undefined
  )
    TriggeringReasonCategories = dataTriggeringReasonCategoriesQuery;

  return (
    <Container fluid>
      <Row>
        <div className={"offset-md-2"}></div>
        {moduleName === "describe" ? (
          <Col className={"bodyCustomColpadding"} md={8}>
            <Describe
              info={data}
              incidentCauses={incidentCauses}
              learningPoints={learningPoints}
            />
          </Col>
        ) : (
          <>
            <SystemFilter
              IncidentTypes={IncidentTypes}
              Systems={Systems}
              Areas={Areas}
              TriggeringReasons={TriggeringReasons}
              triggeringReason2ndLevel={TriggeringReasonCategories}
              Operations={Operations}
              Operation2ndlevel={Operation2ndlevel}
              equipmentInvolved={equipmentInvolved}
            />
            <DetailsTitleBar
              incidents={data}
              incidentCauses={incidentCauses}
              IncidentTypes={IncidentTypes}
              Systems={Systems}
              Areas={Areas}
              TriggeringReasons={TriggeringReasons}
              triggeringReason2ndLevel={TriggeringReasonCategories}
              Operations={Operations}
              Operation2ndlevel={Operation2ndlevel}
              equipmentInvolved={equipmentInvolved}
            />
            <Col md={2} className={"d-none d-md-block d-lg-block d-xl-block"}>
              <Chart
                device="desktop"
                IncidentTypes={IncidentTypes}
                Systems={Systems}
                Areas={Areas}
                TriggeringReasons={TriggeringReasons}
                triggeringReason2ndLevel={TriggeringReasonCategories}
                Operations={Operations}
                Operation2ndlevel={Operation2ndlevel}
                equipmentInvolved={equipmentInvolved}
              />
            </Col>
          </>
        )}
        <div className={"offset-md-2"}></div>
      </Row>
    </Container>
  );
};

export default Body;
