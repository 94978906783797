import React, { memo } from "react";
import { Col } from "react-bootstrap";
import { Row, Container } from "react-bootstrap";
import Checkbox from "../checkBox/CheckBox";
import "./Styles.css";
import Text from "../text/Text";
interface Props {
  text: string;
  total: number;
  id: any;
  category: string;
}

const CheckboxWithText: React.FC<Props> = memo(
  ({ total, text, id, category }) => {
    return (
      <Container fluid className={"checkBoxWithContainer"}>
        <Row>
          <Col md={2} xs={2}>
            <Checkbox id={id} category={category} />
          </Col>
          <Col md={7} xs={8}>
            <Text text={text} fontType="regular" />
          </Col>
          <Col md={2} xs={2} style={{ alignContent: "flex-end" }}>
            <Text text={`(${total.toString()})`} fontType="regular" />
          </Col>
        </Row>
      </Container>
    );
  }
);

export default CheckboxWithText;
