// import { Incidents } from "../../@constants/types";
import { call, put, select } from "redux-saga/effects";
import { resetAppResult } from "../../@actions/SearchFilter";

import yearWiseArrayCreator from "./yearCreator";
import set_MTO_CategoryValue from "./mtOperationCreator";

export const resetFilter = function* (action: any): any {
  try {
    const state = yield select();
    const incidents = state.FILTER.incidentsStored;

    const mtom = yield call(set_MTO_CategoryValue, incidents, "mtom");
    const mtoo = yield call(set_MTO_CategoryValue, incidents, "mtoo");
    const mtot = yield call(set_MTO_CategoryValue, incidents, "mtot");

    const dateValue = yield call(yearWiseArrayCreator, incidents);

    yield put(resetAppResult(mtom, mtoo, mtot, dateValue, incidents));
  } catch (err) {
    console.log("Error is resetFilter", err);
  }
};
