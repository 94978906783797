import { Incidents } from "../../@constants/types";

const yearWiseArrayCreator = (data: Incidents) => {
  let yearValue: any = {
    en: {},
    nb: {},
  };

  yearValue["en"] = initYearValueSet(data, "en");
  yearValue["nb"] = initYearValueSet(data, "nb");

  return yearValue;
};

const initYearValueSet = (data: Incidents, lang: keyof Incidents) => {
  let DemoItem: any = [];

  data[lang].map((e: any) => {
    let TittleDate: string = e.incidentDate.substring(0, 4);

    let data_is = {
      title: TittleDate,
      incidents: 1,
      id: [e.incidentDate],
    };

    if (DemoItem.length === 0) {
      DemoItem.push(data_is);
    } else {
      add(DemoItem, TittleDate, e.incidentDate);
    }
  });

  return DemoItem;
};

function add(arr: any, name: any, id: string) {
  const { length } = arr;
  const title = name;
  const found = arr.some((el: any) =>
    el.title === name
      ? ((el.incidents = el.incidents + 1), (el.id = [...el.id, id]))
      : null
  );
  if (!found) arr.push({ title, incidents: 1, id: [id] });
  return arr;
}

export const yearWiseArrayUpdate = (data: Incidents) => {
  let return_Year = {
    en: {},
    nb: {},
  };

  return_Year["en"] = returnYearValue(data, "en");
  return_Year["nb"] = returnYearValue(data, "nb");

  return return_Year;
};

const returnYearValue = (data: Incidents, lang: keyof Incidents) => {
  let return_data: any = {};

  data[lang].map((e: any) => {
    let TittleDate: string = e.incidentDate.substring(0, 4);

    if (return_data[TittleDate] === undefined) {
      return_data[TittleDate] = 1;
    } else {
      return_data[TittleDate] = return_data[TittleDate] + 1;
    }
  });

  return return_data;
};

export default yearWiseArrayCreator;
