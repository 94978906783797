import PropTypes from "prop-types";

import { Incidents } from "../../@constants/types";

export const searchingStatus = (
  searchKey: "",
  value: {
    ingress: {
      text: "";
    };
    title: "";
    description: {
      text: "";
    };
    learningPoints: {
      text: "";
    };
    incidentCauses: {
      text: "";
    };
    incidentNumber: "";
  }
) => {
  try {
    let ingressTittle = value.title,
      SubDescribe = value.ingress.text,
      description = value.description.text,
      learningPoints = value.learningPoints.text,
      incidentCauses = value.incidentCauses.text,
      incidentNumber = value.incidentNumber;

    if (searchKey === "") {
      return value;
    } else {
      if (
        ingressTittle.toLowerCase().includes(searchKey) ||
        SubDescribe.toLowerCase().includes(searchKey) ||
        description.toLowerCase().includes(searchKey) ||
        learningPoints.toLowerCase().includes(searchKey) ||
        incidentCauses.toLowerCase().includes(searchKey) ||
        incidentNumber.toLowerCase().includes(searchKey)
      ) {
        return value;
      }
    }
  } catch (err) {
    console.log("Error is searchingStatus ", err);
  }
};

const generateSearchingStatus = (searchKey: string, item: any) => {
  try {
    let ingressTittle = item.title,
      SubDescribe = item.ingress.text,
      description = item.description.text,
      learningPoints = item.learningPoints.text,
      incidentCauses = item.incidentCauses.text,
      incidentNumber = item.incidentNumber,
      equipment = item.equipment.equipmentInvolved,
      system = item.system.title,
      subOperation = item.subOperation.title,
      subTriggeringReason = item.triggeringReasonCategory.title,
      triggeringReason = item.triggeringReason[0].title,
      operations = item.operations[0].title,
      areas = item.areas[0].title;

    if (
      ingressTittle.toLowerCase().includes(searchKey.toLowerCase()) ||
      SubDescribe.toLowerCase().includes(searchKey.toLowerCase()) ||
      description.toLowerCase().includes(searchKey.toLowerCase()) ||
      learningPoints.toLowerCase().includes(searchKey.toLowerCase()) ||
      incidentCauses.toLowerCase().includes(searchKey.toLowerCase()) ||
      incidentNumber.toLowerCase().includes(searchKey.toLowerCase()) ||
      equipment.toLowerCase().includes(searchKey.toLowerCase()) ||
      system.toLowerCase().includes(searchKey.toLowerCase()) ||
      subOperation.toLowerCase().includes(searchKey.toLowerCase()) ||
      subTriggeringReason.toLowerCase().includes(searchKey.toLowerCase()) ||
      triggeringReason.toLowerCase().includes(searchKey.toLowerCase()) ||
      operations.toLowerCase().includes(searchKey.toLowerCase()) ||
      areas.toLowerCase().includes(searchKey.toLowerCase())
    ) {
      return item;
    }
  } catch (err) {
    console.log("Err in err ", err);
  }
};

export const searchResult = (info: Incidents, searchingItem: string) => {
  try {
    let return_item: any = {
      en: [],
      nb: [],
    };

    return_item["en"] = info["en"].filter((e) => {
      if (generateSearchingStatus(searchingItem, e) !== undefined) {
        return e;
      }
    });

    return_item["nb"] = info["nb"].filter((e) => {
      if (generateSearchingStatus(searchingItem, e) !== undefined) {
        return e;
      }
    });

    return return_item;
  } catch (err) {
    console.log("Error in searchResult ", err);
  }
};

searchingStatus.propTypes = {
  searchKey: PropTypes.string,
  value: PropTypes.object,
};
