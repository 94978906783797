import React, { useMemo } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import "./Styles.css";
import BarChart from "../../@library/barChart/BarChart";
import HorizontalChart from "../../@library/horizontalChart/HorizontalChart";

import { useTranslation } from "react-i18next";

interface ChartProps {
  device: string;
  IncidentTypes: any;
  Systems: any;
  Areas: any;
  TriggeringReasons: any;
  triggeringReason2ndLevel: any;
  Operations: any;
  Operation2ndlevel: any;
  equipmentInvolved: any;
}

const Chart: React.FC<ChartProps> = ({
  device,
  IncidentTypes,
  Systems,
  Areas,
  TriggeringReasons,
  triggeringReason2ndLevel,
  Operations,
  Operation2ndlevel,
  equipmentInvolved,
}) => {
  const showSmallDevice = useMemo(
    () => (device === "mobile" ? "d-block d-sm-block d-md-none" : " "),
    [device]
  );

  const lang = useSelector((state: any) => state.LANG.lang);
  const { t } = useTranslation();

  var IncidentTypesGraph,
    SystemsGraph,
    AreasGraph,
    TriggeringReasonsGraph,
    triggeringReason2ndLevelGraph,
    Operation2ndlevelGraph,
    equipmentInvolvedGraph,
    OperationsGraph;

  if (IncidentTypes[lang] !== undefined) {
    let labelData = IncidentTypes[lang].map((e: any) => e.title);
    let Data = IncidentTypes[lang].map((e: any) => e.incidents.length);

    IncidentTypesGraph = {
      labels: labelData,
      datasets: [
        {
          label: "",
          backgroundColor: "rgba(161, 177, 184, 0.6)",
          borderColor: "#A1AEB7",
          borderWidth: 1,
          hoverBackgroundColor: "#C2D1D9",
          hoverBorderColor: "#C2D1D9",
          data: Data,
        },
      ],
    };
  }

  if (Systems[lang] !== undefined) {
    let labelData: any = Systems[lang].map((e: any) => e.title.toString());

    let Data = Systems[lang].map((e: any) => e.incidents.length);

    SystemsGraph = {
      labels: labelData,
      datasets: [
        {
          label: "",
          backgroundColor: "rgba(161, 177, 184, 0.6)",
          borderColor: "#A1AEB7",
          borderWidth: 1,
          hoverBackgroundColor: "#C2D1D9",
          hoverBorderColor: "#C2D1D9",
          data: Data,
        },
      ],
    };
  }

  if (Areas[lang] !== undefined) {
    let labelData = Areas[lang].map((e: any) => e.title);
    let Data = Areas[lang].map((e: any) => e.incidents.length);

    AreasGraph = {
      labels: labelData,
      datasets: [
        {
          label: "",
          backgroundColor: "rgba(161, 177, 184, 0.6)",
          borderColor: "#A1AEB7",
          borderWidth: 1,
          hoverBackgroundColor: "#C2D1D9",
          hoverBorderColor: "#C2D1D9",
          data: Data,
        },
      ],
    };
  }

  if (TriggeringReasons[lang] !== undefined) {
    let labelData = TriggeringReasons[lang].map((e: any) => e.title);
    let Data = TriggeringReasons[lang].map((e: any) => e.incidents.length);

    TriggeringReasonsGraph = {
      labels: labelData,
      datasets: [
        {
          label: "",
          backgroundColor: "rgba(161, 177, 184, 0.6)",
          borderColor: "#A1AEB7",
          borderWidth: 1,
          hoverBackgroundColor: "#C2D1D9",
          hoverBorderColor: "#C2D1D9",
          data: Data,
        },
      ],
    };
  }

  if (Operations[lang] !== undefined) {
    let labelData = Operations[lang].map((e: any) => e.title);
    let Data = Operations[lang].map((e: any) => e.incidents.length);

    OperationsGraph = {
      labels: labelData,
      datasets: [
        {
          label: "",
          backgroundColor: "rgba(161, 177, 184, 0.6)",
          borderColor: "#A1AEB7",
          borderWidth: 1,
          hoverBackgroundColor: "#C2D1D9",
          hoverBorderColor: "#C2D1D9",
          data: Data,
        },
      ],
    };
  }

  if (triggeringReason2ndLevel[lang] !== undefined) {
    let labelData = triggeringReason2ndLevel[lang].map((e: any) => e.title);
    let Data = triggeringReason2ndLevel[lang].map(
      (e: any) => e.incidents.length
    );

    triggeringReason2ndLevelGraph = {
      labels: labelData,
      datasets: [
        {
          label: "",
          backgroundColor: "rgba(161, 177, 184, 0.6)",
          borderColor: "#A1AEB7",
          borderWidth: 1,
          hoverBackgroundColor: "#C2D1D9",
          hoverBorderColor: "#C2D1D9",
          data: Data,
        },
      ],
    };
  }

  if (Operation2ndlevel[lang] !== undefined) {
    let labelData = Operation2ndlevel[lang].map((e: any) => e.title);
    let Data = Operation2ndlevel[lang].map((e: any) => e.incidents.length);

    Operation2ndlevelGraph = {
      labels: labelData,
      datasets: [
        {
          label: "",
          backgroundColor: "rgba(161, 177, 184, 0.6)",
          borderColor: "#A1AEB7",
          borderWidth: 1,
          hoverBackgroundColor: "#C2D1D9",
          hoverBorderColor: "#C2D1D9",
          data: Data,
        },
      ],
    };
  }

  if (equipmentInvolved[lang] !== undefined) {
    let labelData = equipmentInvolved[lang].map(
      (e: any) => e.equipmentInvolved
    );
    let Data = equipmentInvolved[lang].map((e: any) => e.incidents.length);

    equipmentInvolvedGraph = {
      labels: labelData,
      datasets: [
        {
          label: "",
          backgroundColor: "rgba(161, 177, 184, 0.6)",
          borderColor: "#A1AEB7",
          borderWidth: 1,
          hoverBackgroundColor: "#C2D1D9",
          hoverBorderColor: "#C2D1D9",
          data: Data,
        },
      ],
    };
  }

  return (
    <>
      <Container fluid className={showSmallDevice}>
        <Row>
          <Col className="barStyleVertical">
            {Systems[lang] !== undefined ? (
              Systems[lang].length < 6 ? (
                <BarChart data={SystemsGraph} name={t("Systems")} />
              ) : (
                <HorizontalChart data={SystemsGraph} name={t("Systems")} />
              )
            ) : null}
          </Col>
        </Row>

        <Row>
          <Col className="barStyleVertical">
            {Areas[lang] !== undefined ? (
              Areas[lang].length < 6 ? (
                <BarChart data={AreasGraph} name={t("Areas")} />
              ) : (
                <HorizontalChart data={AreasGraph} name={t("Areas")} />
              )
            ) : null}
          </Col>
        </Row>

        <Row>
          <Col className="barStyleVertical">
            {Operations[lang] !== undefined ? (
              Operations[lang].length < 6 ? (
                <BarChart data={OperationsGraph} name={t("Operations")} />
              ) : (
                <HorizontalChart
                  data={OperationsGraph}
                  name={t("Operations")}
                />
              )
            ) : null}
          </Col>
        </Row>

        <Row>
          <Col className="barStyleVertical">
            {Operation2ndlevel[lang] !== undefined ? (
              Operation2ndlevel[lang].length < 6 ? (
                <BarChart
                  data={Operation2ndlevelGraph}
                  name={t("SubOperations")}
                />
              ) : (
                <HorizontalChart
                  data={Operation2ndlevelGraph}
                  name={t("SubOperations")}
                />
              )
            ) : null}
          </Col>
        </Row>

        <Row>
          <Col className="barStyleHorizontalEquipments">
            {equipmentInvolved[lang] !== undefined ? (
              <HorizontalChart
                data={equipmentInvolvedGraph}
                name={t("Equipments")}
              />
            ) : null}
          </Col>
        </Row>

        <Row>
          <Col className="barStyleHorizontalCauses">
            {TriggeringReasons[lang] !== undefined ? (
              TriggeringReasons[lang].length < 6 ? (
                <BarChart
                  data={TriggeringReasonsGraph}
                  name={t("TriggeringReasons")}
                />
              ) : (
                <HorizontalChart
                  data={TriggeringReasonsGraph}
                  name={t("TriggeringReasons")}
                />
              )
            ) : null}
          </Col>
        </Row>

        <Row>
          <Col className="barStyleVertical">
            {triggeringReason2ndLevel[lang] !== undefined ? (
              <HorizontalChart
                data={triggeringReason2ndLevelGraph}
                name={t("SubTriggeringReason")}
              />
            ) : null}
          </Col>
        </Row>

        <Row>
          <Col className="barStyleVertical">
            {IncidentTypes[lang] !== undefined ? (
              <HorizontalChart
                data={IncidentTypesGraph}
                name={t("LeakageType")}
              />
            ) : null}
          </Col>
        </Row>
      </Container>
    </>
  );
};
export default Chart;
