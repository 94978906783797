import { HorizontalBar } from "react-chartjs-2";
import React, { memo } from "react";

interface HorizontalChartProps {
  data: any;
  name: string;
}

const HorizontalChart: React.FC<HorizontalChartProps> = memo(
  ({ data, name }) => {
    return (
      <>
        <HorizontalBar
          data={data}
          options={{
            indexAxis: "y",
            maintainAspectRatio: false,
            responsive: true,
            title: {
              display: true,
              text: name,
              fontSize: 20,
            },
            legend: {
              display: false,
            },
            scales: {
              xAxes: [
                {
                  stacked: true,
                  gridLines: {
                    display: false,
                  },
                  offset: true,
                },
              ],
              yAxes: [
                {
                  stacked: true,
                  ticks: {
                    fontColor: "#000000",
                    display: true,
                    mirror: true,
                    padding: -28,
                  },
                  gridLines: {
                    display: false,
                  },
                },
              ],
            },
          }}
        />
      </>
    );
  }
);

export default HorizontalChart;
