export const CHANGED_LANGUAGE = "CHANGED_LANGUAGE";
export const UPDATE_LANGUAGE = "UPDATE_LANGUAGE";

export const FILTERING = "FILTERING";
export const FILTERING_RESULT = "FILTERING_RESULT";

export const SEARCHING = "SEARCHING";

export const INIT_APP_LOAD_RESULT = "INIT_APP_LOAD_RESULT";

export const FILTERING_RESULT_YEAR = "FILTERING_RESULT_YEAR";

export const SHOW_FILTER = "SHOW_FILTER";

export const STORE_FILTER_ID = "STORE_FILTER_ID";

export const INIT_APP_LOAD = "INIT_APP_LOAD";

export const DOWNLOAD_URL = "DOWNLOAD_URL";

export const RESET_FILTER = "RESET_FILTER";

export const RESULT_FILTER_RESULT = "RESULT_FILTER_RESULT";

export const SHOW_FILTER_SMALL_DEVICE = "SHOW_FILTER_SMALL_DEVICE";
