import React, { useEffect } from "react";

import { useQuery } from "@apollo/client";

import MyQuery from "../../@services/IncidensQuery";
import Header from "../../@component/header/Header";
import Body from "../../@component/body/Body";
import { initAppLoad } from "../../@actions/SearchFilter";

import { useTranslation } from "react-i18next";

import { useDispatch, useSelector } from "react-redux";

const Main = () => {
  const { data, error, loading } = useQuery(MyQuery, {
    fetchPolicy: "no-cache",
  });

  const store_incidentTypeId = useSelector(
    (state: any) => state.FILTER.store_incidentTypeId
  );
  const store_areaId = useSelector((state: any) => state.FILTER.store_areaId);
  const store_systemId = useSelector(
    (state: any) => state.FILTER.store_systemId
  );
  const store_triggering_reasonId = useSelector(
    (state: any) => state.FILTER.store_triggering_reasonId
  );
  const store_sub_triggering_reasonId = useSelector(
    (state: any) => state.FILTER.store_sub_triggering_reasonId
  );
  const store_operationId = useSelector(
    (state: any) => state.FILTER.store_operationId
  );
  const store_sub_operationId = useSelector(
    (state: any) => state.FILTER.store_sub_operationId
  );
  const store_equipmentId = useSelector(
    (state: any) => state.FILTER.store_equipmentId
  );

  const storedDate = useSelector((state: any) => state.FILTER.storedDate);

  const store_mto_t = useSelector((state: any) => state.FILTER.store_mto_t);

  const store_mto_m = useSelector((state: any) => state.FILTER.store_mto_m);

  const store_mto_o = useSelector((state: any) => state.FILTER.store_mto_o);

  const currentSearchAction = useSelector(
    (state: any) => state.FILTER.currentSearchAction
  );

  var Incidents: any = [];

  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    if (
      loading === false &&
      data !== undefined &&
      error === undefined &&
      store_systemId.length === 0 &&
      store_incidentTypeId.length === 0 &&
      store_areaId.length === 0 &&
      store_equipmentId.length === 0 &&
      store_operationId.length === 0 &&
      store_sub_operationId.length === 0 &&
      store_triggering_reasonId.length === 0 &&
      store_sub_triggering_reasonId.length === 0 &&
      store_mto_m.length === 0 &&
      store_mto_o.length === 0 &&
      store_mto_t.length === 0 &&
      storedDate.length === 0 &&
      currentSearchAction === ""
    ) {
      dispatch(initAppLoad(data));
    }
  }, [
    data,
    error,
    loading,
    dispatch,
    store_incidentTypeId,
    store_systemId,
    store_areaId,
    store_equipmentId,
    store_operationId,
    store_sub_operationId,
    store_triggering_reasonId,
    store_sub_triggering_reasonId,
    store_mto_m,
    store_mto_o,
    store_mto_t,
    storedDate,
    currentSearchAction,
  ]);

  return (
    <>
      <Header
        moduleName="main"
        mainTitle={t("mainTittle")}
        subTitle={t("subTittle")}
        systems={"no data"}
        operations={"no data"}
        area={"no data"}
        triggeringReason={"no data"}
        downloadUrl={""}
      />

      <Body
        data={Incidents}
        moduleName="main"
        incidentCauses={Incidents}
        learningPoints={Incidents}
      />
    </>
  );
};

export default Main;
