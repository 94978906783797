import React, { memo } from "react";
import Highlighter from "react-highlight-words";
import { useSelector } from "react-redux";
import "../text/Styles.css";

interface HighlighterTextTextProps {
  text: string;
  font: string;
}

const HighlighterText: React.FC<HighlighterTextTextProps> = memo(
  ({ text, font }) => {
    const searchingItem = useSelector(
      (state: any) => state.FILTER.searchingItem
    );
    return (
      <Highlighter
        highlightClassName="YourHighlightClass"
        searchWords={[searchingItem]}
        autoEscape={true}
        textToHighlight={text}
        className={font === "bold" ? "customTextBold" : "customTextRegular"}
      />
    );
  }
);

export default HighlighterText;
