import { call, put, select } from "redux-saga/effects";
import { filterCategoryIDCreator } from "./categoryIdCreator";
import { filterResult } from ".././../@actions/SearchFilter";

import { yearWiseArrayUpdate } from "./yearCreator";
import set_MTO_CategoryValue from "./mtOperationCreator";

import { searchResult } from "./searchingItems";

export const filterDataSave = function* (action: any): any {
  try {
    const state = yield select();

    let incidents;

    // const lang = state.LANG.lang;

    const searchingItem = state.FILTER.searchingItem;

    const result = {
      en: {},
      nb: {},
    };

    if (searchingItem === "") {
      incidents = action.incidents;
    } else {
      incidents = yield call(searchResult, action.incidents, searchingItem);
    }

    result["en"] = yield call(filterCategoryIDCreator, incidents["en"]);
    result["nb"] = yield call(filterCategoryIDCreator, incidents["nb"]);

    const mtom = yield call(set_MTO_CategoryValue, incidents, "mtom");
    const mtoo = yield call(set_MTO_CategoryValue, incidents, "mtoo");
    const mtot = yield call(set_MTO_CategoryValue, incidents, "mtot");

    const dateValue = yield call(yearWiseArrayUpdate, incidents);

    yield put(filterResult(incidents, result, mtom, mtoo, mtot, dateValue));
  } catch (err) {
    console.log("error is", err);
  }
};
