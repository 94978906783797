import { CHANGED_LANGUAGE } from "../@constants/Constants";
import { LangActionTypes } from "../@constants/types";

// TypeScript infers that this function is returning DeleteMessageAction
export function changeLanguage(lang: string): LangActionTypes {
  return {
    type: CHANGED_LANGUAGE,
    lang: lang,
  };
}
