import React, { useCallback, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { storedDownloadUrl } from "../../@constants/types";

import { setDownloadUrl } from "../../@actions/SearchFilter";

const CheckBoxElement: React.FC<CheckBoxElementProps> = ({
  id = "",
  name = "",
}) => {
  const [Check, setCheck] = useState(false);

  const dispatch = useDispatch();

  const storedDownloadUrl = useSelector(
    (state: any) => state.FILTER.storedDownloadUrl
  );

  const store_incident = useSelector(
    (state: any) => state.FILTER.incidentsStored
  );

  // const lang = useSelector((state: any) => state.LANG.lang);

  const [save_ID] = useState(id);

  const onClicked = useCallback(
    (e) => {
      let save_data: storedDownloadUrl = {
        en: [],
        nb: [],
      };

      // save multiple value from static en language

      store_incident["en"].map((incident: any) => {
        if (save_ID === incident.id) {
          if (save_ID.length !== 0) {
            if (Check === false) {
              save_data["en"] = [
                ...storedDownloadUrl["en"],
                incident.attachmentEn[0].url,
              ];
              save_data["nb"] = [
                ...storedDownloadUrl["nb"],
                incident.attachmentNo[0].url,
              ];
            } else {
              save_data["en"] = storedDownloadUrl["en"].filter((e: string) => {
                if (e.toString() !== incident.attachmentEn[0].url.toString())
                  return e;
              });
              save_data["nb"] = storedDownloadUrl["nb"].filter((e: string) => {
                if (e.toString() !== incident.attachmentNo[0].url.toString())
                  return e;
              });
            }
            dispatch(setDownloadUrl(save_data));
          }
        }
      });

      setCheck(!Check);
    },
    [Check, storedDownloadUrl, dispatch, save_ID, store_incident]
  );

  return (
    <>
      <div className="grid">
        <label className="checkbox path">
          <input
            type="checkbox"
            checked={Check}
            //defaultChecked={check}
            onChange={onClicked}
          />
          <svg viewBox="0 0 21 21">
            <path d="M5,10.75 L8.5,14.25 L19.4,2.3 C18.8333333,1.43333333 18.0333333,1 17,1 L4,1 C2.35,1 1,2.35 1,4 L1,17 C1,18.65 2.35,20 4,20 L17,20 C18.65,20 20,18.65 20,17 L20,7.99769186"></path>
          </svg>
        </label>
      </div>
    </>
  );
};

interface CheckBoxElementProps {
  id: string;
  name: string;
}

export default CheckBoxElement;
