import React, { useMemo, memo } from "react";
import "./Styles.css";
interface TextProps {
  text: string | null;
  fontType: string;
}

const Text: React.FC<TextProps> = memo(({ text, fontType }) => {
  const propsText = useMemo(() => (text !== "" ? text : " "), [text]);
  const classStyle = useMemo(
    () =>
      fontType === "regular"
        ? "customTextRegular"
        : fontType === "bold"
        ? "customTextBold"
        : fontType === "semiBold"
        ? "SemiBoldText"
        : "customTextMedium",
    [fontType]
  );
  return <span className={classStyle}>{propsText}</span>;
});

export default Text;
