import { Incidents, ModelIncidents } from "../../@constants/types";
import { call } from "redux-saga/effects";
interface itemProps {
  [key: string]: number;
}

// dynamic create mtoo mtoo mooo value

const set_MTO_CategoryValue = function* (
  info: Incidents,
  name_category: keyof ModelIncidents
): any {
  try {
    let return_array = {
      ["en"]: {},
      ["nb"]: {},
    };

    let en = {
      True: 0,
      False: 0,
    };

    let nb = {
      True: 0,
      False: 0,
    };

    return_array["en"] = yield call(
      objectCreator,
      info["en"],
      name_category,
      en
    );
    return_array["nb"] = yield call(
      objectCreator,
      info["nb"],
      name_category,
      nb
    );

    return return_array;
  } catch (err) {
    console.log("Error is initialAppData", err);
  }
};

const objectCreator = (
  info: Array<ModelIncidents>,
  name_category: keyof ModelIncidents,
  item: itemProps
) => {
  try {
    info.map((e) => {
      e[name_category] === true
        ? (item["True"] = item["True"] + 1)
        : (item["False"] = item["False"] + 1);

      return e;
    });

    return item;
  } catch (err) {
    console.log("Error in objectCreator ", err);
  }
};

export default set_MTO_CategoryValue;
