import React, { memo, useState, useMemo, useCallback, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import {
  resetFilter,
  showFilterResultSmallDevice,
} from "../../@actions/SearchFilter";

import "./Styles.css";
import Text from "../../@library/text/Text";
import CheckboxWithText from "../../@library/checkboxWithText/CheckboxWithText";

import SearchFilter from "../../@library/searchFilter/SearchFilter";
import { GoChevronDown } from "react-icons/go";

import { useTranslation } from "react-i18next";
import { useResizeDetector } from "react-resize-detector";

interface RowsProps {
  data: any;
  name: string;
  title: string;
  category: string;
}

interface SystemFilterProps {
  IncidentTypes: any;
  Systems: any;
  Areas: any;
  TriggeringReasons: any;
  triggeringReason2ndLevel: any;
  Operations: any;
  Operation2ndlevel: any;
  equipmentInvolved: any;
}

const SystemFilter: React.FC<SystemFilterProps> = ({
  IncidentTypes,
  Systems,
  Areas,
  TriggeringReasons,
  triggeringReason2ndLevel,
  Operations,
  Operation2ndlevel,
  equipmentInvolved,
}) => {
  const { t: translate } = useTranslation();
  const filter_view_status = useSelector(
    (state: any) => state.FILTER.filter_view_status
  );

  const showSmallDevice = useMemo(
    () => (filter_view_status === "" ? "d-none" : "d-block"),
    [filter_view_status]
  );

  const dispatch = useDispatch();

  const lang = useSelector((state: any) => state.LANG.lang);
  const mtom = useSelector((state: any) => state.FILTER.mtom);
  const mtoo = useSelector((state: any) => state.FILTER.mtoo);
  const mtot = useSelector((state: any) => state.FILTER.mtot);
  const incidentYearWiseStore = useSelector(
    (state: any) => state.FILTER.incidentYearWiseStore
  );

  const reset_filter_now = useCallback(
    (e) => {
      e.preventDefault();
      dispatch(resetFilter());
    },
    [dispatch]
  );

  const onShowFilterResult = useCallback(
    (e) => {
      e.preventDefault();
      dispatch(showFilterResultSmallDevice());
    },
    [dispatch]
  );

  const { width, ref } = useResizeDetector();

  //HTMLInputElement
  const fieldRef = ref as React.MutableRefObject<any>;

  const currentSearchAction = useSelector(
    (state: any) => state.FILTER.currentSearchAction
  );

  const [showFilterResult, setShowFilterResult] = useState(false);

  useEffect(() => {
    if (
      width !== undefined &&
      width <= 750 &&
      showSmallDevice === "d-block" &&
      currentSearchAction === "filter"
    ) {
      setShowFilterResult(true);
    } else {
      setShowFilterResult(false);
    }
  }, [width, showSmallDevice, currentSearchAction]);

  return (
    <Col
      className={
        "systemFilterView  d-md-block d-lg-block d-xl-block " + showSmallDevice
      }
      md={2}
      ref={fieldRef}
    >
      <Row>
        <SearchFilter />
      </Row>

      <Row>
        <div className="row2PaddingLeft font-weight-bold">
          {showFilterResult === false ? null : (
            <a
              style={{ paddingTop: 10, float: "left" }}
              href="window.location"
              className="nullstill-filtere "
              onClick={onShowFilterResult}
            >
              {"show filter result"}
            </a>
          )}
        </div>

        <div className="row2PaddingRight font-weight-bold">
          <a
            style={{ paddingTop: 10 }}
            href="window.location"
            className="nullstill-filtere "
            onClick={reset_filter_now}
          >
            {translate("resetFilter")}
          </a>
        </div>
      </Row>

      <AllRows
        data={Systems}
        name={translate("Systems")}
        title={"title"}
        category={"Systems"}
      />

      <AllRows
        data={Areas}
        name={translate("Areas")}
        title={"title"}
        category={"Areas"}
      />

      <AllRows
        data={Operations}
        name={translate("Operations")}
        title={"title"}
        category={"Operations"}
      />

      <AllRows
        data={Operation2ndlevel}
        name={translate("SubOperations")}
        title={"Sub Operations"}
        category={"SubOperations"}
      />

      <AllRows
        data={equipmentInvolved}
        name={translate("Equipments")}
        title={"equipments"}
        category={"Equipments"}
      />

      <AllRows
        data={TriggeringReasons}
        name={translate("TriggeringReasons")}
        title={"title"}
        category={"TriggeringReasons"}
      />

      <AllRows
        data={triggeringReason2ndLevel}
        name={translate("SubTriggeringReason")}
        title={"triggeringReasonCategoriesQuery"}
        category={"SubTriggeringReason"}
      />

      <AllRows
        data={IncidentTypes}
        name={translate("LeakageType")}
        title={"title"}
        category={"IncidentTypes"}
      />

      <Row style={{ paddingBottom: 30 }}>
        <Text text={translate("mto_filter")} fontType="medium" />

        <CheckboxWithText
          total={mtom[lang].length !== 0 ? mtom[lang].True : 0}
          text={translate("Man")}
          id={true}
          category={"mto-m"}
        />

        <CheckboxWithText
          total={mtot[lang].length !== 0 ? mtot[lang].True : 0}
          text={translate("Technical")}
          id={true}
          category={"mto-t"}
        />
        <CheckboxWithText
          total={mtoo[lang].length !== 0 ? mtoo[lang].True : 0}
          text={translate("Organizational")}
          id={true}
          category={"mto-o"}
        />
      </Row>

      <AllRows
        data={incidentYearWiseStore}
        name={translate("Years")}
        title={"Årstall"}
        category={"Years"}
      />
    </Col>
  );
};

const AllRows: React.FC<RowsProps> = memo(({ data, name, title, category }) => {
  const lang = useSelector((state: any) => state.LANG.lang);
  const currentSearchAction = useSelector(
    (state: any) => state.FILTER.currentSearchAction
  );
  const set_category_value = useSelector(
    (state: any) => state.FILTER.set_category_value
  );

  const year_value_update = useSelector(
    (state: any) => state.FILTER.year_value_update
  );

  const { t } = useTranslation();
  const [limit, setLimit] = useState(4);
  const [showMore, setShowMore] = useState(true);

  let showingArray: any = {
    en: [],
    nb: [],
  };
  if (data[lang] !== undefined) showingArray[lang] = data[lang].slice(0, limit);

  const handleClick: any = (event: MouseEvent) => {
    event.preventDefault();
    setLimit(data[lang].length);
    setShowMore(false);
  };

  const getTotalValue = (e: any) => {
    if (currentSearchAction === "" && currentSearchAction !== "filter") {
      return title === "Årstall" ? e.incidents : e.incidents.length;
    } else {
      if (Object.keys(set_category_value[lang]).length !== 0) {
        if (name === "Lekkasje type" || name === "Leakage Type") {
          return set_category_value[lang].incidentType[e.title] !== undefined
            ? set_category_value[lang].incidentType[e.title]
            : 0;
        } else if (name === "System" || name === "System") {
          return set_category_value[lang].system[e.title] !== undefined
            ? set_category_value[lang].system[e.title]
            : 0;
        } else if (name === "Areas" || name === "Område") {
          return set_category_value[lang].areas[e.title] !== undefined
            ? set_category_value[lang].areas[e.title]
            : 0;
        } else if (name === "Triggering Reason" || name === "Utløsende årsak") {
          return set_category_value[lang].triggeringReason[e.title] !==
            undefined
            ? set_category_value[lang].triggeringReason[e.title]
            : 0;
        } else if (
          name === "Sub Triggering Reason" ||
          name === "Annen utløsende årsak"
        ) {
          return set_category_value[lang].subTriggeringReason[e.title] !==
            undefined
            ? set_category_value[lang].subTriggeringReason[e.title]
            : 0;
        } else if (name === "Operation" || name === "Operasjon") {
          return set_category_value[lang].operations[e.title] !== undefined
            ? set_category_value[lang].operations[e.title]
            : 0;
        } else if (name === "Sub Operation" || name === "Aktivitet") {
          return set_category_value[lang].subOperations[e.title] !== undefined
            ? set_category_value[lang].subOperations[e.title]
            : 0;
        } else if (name === "Equipment" || name === "Utstyr involvert") {
          return set_category_value[lang].equipment[e.equipmentInvolved] !==
            undefined
            ? set_category_value[lang].equipment[e.equipmentInvolved]
            : 0;
        } else {
          return year_value_update[lang][e.title] !== undefined
            ? year_value_update[lang][e.title]
            : 0;
        }
      }
    }
  };

  const RenderButton = () => {
    return data[lang] !== undefined &&
      limit < data[lang].length &&
      showMore === true ? (
      <div className="col text-center">
        <button
          onClick={handleClick}
          className="btn btn-default showMoreButton"
        >
          {t("seeAll")} <GoChevronDown />
        </button>
      </div>
    ) : null;
  };

  return (
    <Row style={{ paddingBottom: 30 }}>
      <Text text={name} fontType="medium" />
      {showingArray[lang].length !== 0
        ? showingArray[lang].map((e: any, i: number) => (
            <CheckboxWithText
              total={getTotalValue(e)}
              text={
                title === "equipments"
                  ? e.equipmentInvolved
                  : title === "Årstall"
                  ? e.title
                  : e.title
              }
              id={title === "Årstall" ? e.id : e.id}
              key={i}
              category={category}
            />
          ))
        : null}

      <RenderButton />
    </Row>
  );
});

export default SystemFilter;
