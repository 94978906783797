import React from "react";
import Text from "../../@library/text/Text";
import "./Styles.css";
import { useTranslation } from "react-i18next";

interface SemiHeaderProps {
  systems: string | null;
  operations: string | null;
  area: string | null;
  triggeringReason: string | null;
}

const SemiHeader: React.FC<SemiHeaderProps> = ({
  systems,
  operations,
  area,
  triggeringReason,
}) => {
  const { t } = useTranslation();

  return (
    <div className="col-md-8 semiHeaderMenu">
      <table className="table table-borderless">
        <tbody>
          <tr>
            <td
              className={
                "customSemiHeaderPaddingleft smallDevicePaddingBottomSemiHeader"
              }
            >
              <Text text="Systems" fontType="semiBold" />
            </td>
            <td
              className={
                "customSemiHeaderPaddingleft smallDevicePaddingBottomSemiHeader"
              }
            >
              <Text text="Operations" fontType="semiBold" />
            </td>
            <td
              className={
                "customSemiHeaderPaddingleft smallDevicePaddingBottomSemiHeader"
              }
            >
              <Text text="Area" fontType="semiBold" />
            </td>
            <td
              className={
                "customSemiHeaderPaddingleft hideSmallDeviceSemiHeaderMenu smallDevicePaddingBottomSemiHeader"
              }
            >
              <Text text={t("TriggeringReason")} fontType="semiBold" />
            </td>
          </tr>

          <tr>
            <td
              className={
                "customSemiHeaderPaddingleft smallDevicePaddingBottomSemiHeader"
              }
            >
              <Text text={systems} fontType="regular" />
            </td>
            <td
              className={
                "customSemiHeaderPaddingleft smallDevicePaddingBottomSemiHeader"
              }
            >
              <Text text={operations} fontType="regular" />
            </td>
            <td
              className={
                "customSemiHeaderPaddingleft smallDevicePaddingBottomSemiHeader"
              }
            >
              <Text text={area} fontType="regular" />
            </td>
            <td
              className={
                "customSemiHeaderPaddingleft hideSmallDeviceSemiHeaderMenu smallDevicePaddingBottomSemiHeader"
              }
            >
              <Text text={triggeringReason} fontType="regular" />
            </td>
          </tr>
        </tbody>
      </table>

      {/* only Show In small Device */}

      <table className="table table-borderles ShowSmallDeviceSemiHeaderMenu">
        <tbody>
          <tr>
            <td
              className={
                "customSemiHeaderPaddingleft smallDevicePaddingBottomSemiHeader"
              }
            >
              <Text text="Triggering Reason" fontType="semiBold" />
            </td>
          </tr>

          <tr>
            <td
              className={
                "customSemiHeaderPaddingleft smallDevicePaddingBottomSemiHeader"
              }
            >
              <Text text={triggeringReason} fontType="regular" />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default SemiHeader;
