import React, { useState, useCallback, useMemo } from "react";
import { Row, Col, Button } from "react-bootstrap";
import { FaFileExport } from "react-icons/fa";
import { useSelector } from "react-redux";
import FlatList from "../../@library/list/FlatList";
import "./Styles.css";
import Chart from "../chart/Chart";

import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import { ShowFilterNow } from "../../@actions/SearchFilter";

import LoaderSpinner from "../../@library/loader/LoaderSpinner";

interface DetailsTitleBarProps {
  incidents: any;
  incidentCauses: any;
  IncidentTypes: any;
  Systems: any;
  Areas: any;
  TriggeringReasons: any;
  triggeringReason2ndLevel: any;
  Operations: any;
  Operation2ndlevel: any;
  equipmentInvolved: any;
}

const DetailsTitleBar: React.FC<DetailsTitleBarProps> = ({
  incidents,
  incidentCauses,
  IncidentTypes,
  Systems,
  Areas,
  TriggeringReasons,
  triggeringReason2ndLevel,
  Operations,
  Operation2ndlevel,
  equipmentInvolved,
}) => {
  const { t } = useTranslation();

  const [filterClassName] = useState("scrollRow");

  const lang = useSelector((state: any) => state.LANG.lang);

  const Incidents = useSelector((state: any) => state.FILTER.incidents);

  const load_query_status = useSelector(
    (state: any) => state.FILTER.load_query_status
  );

  const isEmpty = (obj: any) => {
    for (var key in obj) {
      if (obj.hasOwnProperty(key)) return false;
    }
    return true;
  };

  const filter_view_status = useSelector(
    (state: any) => state.FILTER.filter_view_status
  );

  const showSmallDevice = useMemo(
    () => (filter_view_status === "" ? "d-block" : "d-none"),
    [filter_view_status]
  );

  const storedDownloadUrl = useSelector(
    (state: any) => state.FILTER.storedDownloadUrl
  );

  const dispatch = useDispatch();

  const activateFilter = useCallback(() => {
    dispatch(ShowFilterNow("filterShow"));
  }, [dispatch]);

  const activateDownload = useCallback(
    (e) => {
      e.preventDefault();
      if (storedDownloadUrl.length !== 0) {
        storedDownloadUrl[lang].map((e: string, i: number) => {
          fetch(e)
            .then((res) => res.blob()) // Gets the response and returns it as a blob
            .then((blob) => {
              var filename = "download_" + (i + 1) + ".pdf";
              var elem = window.document.createElement("a");
              elem.href = window.URL.createObjectURL(blob);
              elem.download = filename;
              document.body.appendChild(elem);
              elem.click();
              document.body.removeChild(elem);
            });
        });
      }
    },
    [storedDownloadUrl, lang]
  );

  return (
    <Col md={4} className={"col-sm-12 " + showSmallDevice}>
      <Row>
        <Col>
          <div className="d-flex bd-highlight mb-3">
            <div className="p-2 bd-highlight">
              <a href="window.location" onClick={activateDownload}>
                <FaFileExport color={"#A1AEB7"} />
                <span style={{ color: "#A1AEB7", paddingLeft: 5 }}>
                  {t("download_selected")}
                </span>
              </a>
            </div>

            <div className="ml-auto p-2 bd-highlight d-block d-sm-block d-md-none">
              <Button
                onClick={activateFilter}
                className={"chooseLangBtnNew  poll-right"}
                variant="link"
              >
                Filter
              </Button>
            </div>
          </div>
        </Col>
      </Row>

      <Row className={filterClassName}>
        {load_query_status === false ? (
          <LoaderSpinner />
        ) : isEmpty(Incidents) ? (
          <div></div>
        ) : Incidents.length !== 0 ? (
          Incidents[lang].map((e: any, i: number) => (
            <FlatList
              incidentNumber={e.incidentNumber}
              incidentTitle={e.title}
              incidentDetails={e.ingress.text}
              key={i}
              Id={e.id}
              description={Incidents}
              incidentCauses={incidentCauses}
              itemId={i}
              downloadUrl={[...e.attachmentEn, ...e.attachmentNo]}
              downloadUrlAllData={[...e.attachmentEn, ...e.attachmentNo]}
            />
          ))
        ) : (
          <div></div>
        )}
      </Row>
      <Row>
        <Chart
          device="mobile"
          IncidentTypes={IncidentTypes}
          Systems={Systems}
          Areas={Areas}
          TriggeringReasons={TriggeringReasons}
          triggeringReason2ndLevel={triggeringReason2ndLevel}
          Operations={Operations}
          Operation2ndlevel={Operation2ndlevel}
          equipmentInvolved={equipmentInvolved}
        />
      </Row>
    </Col>
  );
};

export default DetailsTitleBar;
