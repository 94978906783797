import React, { useCallback } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import "./Styles.css";
import { ReactComponent as Logo } from "../../@assets/Images/logo.svg";
import { useDispatch, useSelector } from "react-redux";
import { changeLanguage } from "../../@actions/Language";
import { useTranslation } from "react-i18next";

const LanguageBar = () => {
  const dispatch = useDispatch();
  const { i18n } = useTranslation();

  const lang = useSelector((state: any) => state.LANG.lang);

  const languageEnglish = useCallback(() => {
    dispatch(changeLanguage("en"));
    i18n.changeLanguage("en");
  }, [dispatch, i18n]);

  const languageNorway = useCallback(() => {
    dispatch(changeLanguage("nb"));
    i18n.changeLanguage("nb");
  }, [dispatch, i18n]);

  return (
    <Container className={"languageBar"} fluid>
      <Row style={{ height: "100%" }} className="align-items-center">
        <div className={"offset-md-2"}></div>
        <Col>
          <a href="https://norskoljeoggass.no/drift/storulykkerisiko/hydrokarbonlekkasjer/">
            <Logo />
          </a>
        </Col>
        <Col>
          <div className="d-flex flex-row-reverse ">
            <div className="p-2 chooseLang">
              <Button
                onClick={languageEnglish}
                className={"chooseLangBtn"}
                variant="link"
              >
                <span
                  style={{
                    fontWeight: lang === "en" ? "bold" : "normal",
                  }}
                >
                  En
                </span>
              </Button>
            </div>
            <div className="p-2 chooseLang">
              <Button
                className={"chooseLangBtnDisable"}
                disabled
                variant="link"
              >
                /
              </Button>
            </div>
            <div className="p-2 chooseLang">
              <Button
                onClick={languageNorway}
                className={"chooseLangBtn"}
                variant="link"
              >
                <span
                  style={{
                    fontWeight: lang === "nb" ? "bold" : "normal",
                  }}
                >
                  No
                </span>
              </Button>
            </div>
          </div>
        </Col>
        <div className={"offset-md-2"}></div>
      </Row>
    </Container>
  );
};

export default LanguageBar;
