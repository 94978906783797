import {
  FILTERING,
  FILTERING_RESULT,
  SEARCHING,
  INIT_APP_LOAD_RESULT,
  SHOW_FILTER,
  STORE_FILTER_ID,
  DOWNLOAD_URL,
  RESULT_FILTER_RESULT,
  SHOW_FILTER_SMALL_DEVICE,
} from "../@constants/Constants";

import { GetSearchStateType, FilteringActionTypes } from "../@constants/types";

const initValue = {
  en: [],
  nb: [],
};

const initialStateGetSearchFilter: GetSearchStateType = {
  storedDownloadUrl: {
    en: [],
    nb: [],
  },
  storedDownloadUrlRaw: {
    en: [],
    nb: [],
  },
  storedDate: [],
  incidents: initValue,
  incidentsStored: [],
  year_value_update: {
    en: {},
    nb: {},
  },
  searchingItem: "",
  currentSearchAction: "",
  incidentYearWiseStore: "",
  incidentsSearchDateWise: [],
  filter_view_status: "",
  set_category_value: {},
  filtering_id: {},
  selected_filter_name: [],
  load_query_status: false,

  store_incidentTypeId: [],
  store_areaId: [],
  store_systemId: [],
  store_triggering_reasonId: [],
  store_sub_triggering_reasonId: [],
  store_operationId: [],
  store_sub_operationId: [],
  store_equipmentId: [],

  store_date: initValue,
  mtom: initValue,
  mtoo: initValue,
  mtot: initValue,
  store_mto_t: [],
  store_mto_m: [],
  store_mto_o: [],
  reset_filter: false,
};

const getLangReducer = (
  state = initialStateGetSearchFilter,
  action: FilteringActionTypes
): GetSearchStateType => {
  switch (action.type) {
    case FILTERING:
      return {
        ...state,
        currentSearchAction: "filter",
        load_query_status: false,
        reset_filter: false,
      };
    case FILTERING_RESULT:
      return {
        ...state,
        incidents: action.incidents,
        set_category_value: action.set_category_value,
        load_query_status: true,
        mtom: action.mtom,
        mtoo: action.mtoo,
        mtot: action.mtot,
        year_value_update: action.store_date,
      };

    case INIT_APP_LOAD_RESULT:
      return {
        ...state,
        incidents: action.incidents,
        incidentsStored: action.incidents,
        incidentYearWiseStore: action.store_date,
        mtom: action.mtom,
        mtoo: action.mtoo,
        mtot: action.mtot,
        load_query_status: true,
      };
    case SHOW_FILTER:
      return {
        ...state,
        filter_view_status: action.filter_view_status,
      };
    case SEARCHING:
      return {
        ...state,
        searchingItem: action.search,
        currentSearchAction: "filter",
        load_query_status: false,
      };

    case STORE_FILTER_ID:
      return {
        ...state,
        store_incidentTypeId: action.store_incidentTypeId,
        store_areaId: action.store_areaId,
        store_systemId: action.store_systemId,
        store_triggering_reasonId: action.store_triggering_reasonId,
        store_sub_triggering_reasonId: action.store_sub_triggering_reasonId,
        store_operationId: action.store_operationId,
        store_sub_operationId: action.store_sub_operationId,
        store_equipmentId: action.store_equipmentId,
        storedDate: action.storedDate,
        store_mto_t: action.store_mto_t,
        store_mto_m: action.store_mto_m,
        store_mto_o: action.store_mto_o,
        load_query_status: false,
      };

    case RESULT_FILTER_RESULT:
      return {
        ...state,
        searchingItem: "",
        incidents: action.incidents,
        incidentsStored: action.incidents,
        incidentYearWiseStore: action.store_date,
        mtom: action.mtom,
        mtoo: action.mtoo,
        mtot: action.mtot,
        reset_filter: true,
        currentSearchAction: "",
        store_systemId: [],
        store_areaId: [],
        store_operationId: [],
        store_sub_operationId: [],
        store_sub_triggering_reasonId: [],
        store_triggering_reasonId: [],
        store_incidentTypeId: [],
        store_equipmentId: [],
        store_mto_m: [],
        store_mto_o: [],
        store_mto_t: [],
        storedDate: [],
      };

    case DOWNLOAD_URL:
      return {
        ...state,
        storedDownloadUrl: action.storedDownloadUrl,
      };

    case SHOW_FILTER_SMALL_DEVICE:
      return {
        ...state,
        filter_view_status: "",
      };
    default:
      return state;
  }
};

export default getLangReducer;
