import { STORE_FILTER_ID } from "../@constants/Constants";

import { FilteringActionTypes } from "../@constants/types";

export function setFilterId(
  store_incidentTypeId: string[],
  store_areaId: string[],
  store_systemId: string[],
  store_triggering_reasonId: string[],
  store_sub_triggering_reasonId: string[],
  store_operationId: string[],
  store_sub_operationId: string[],
  store_equipmentId: string[],
  storedDate_Id: string[],
  store_mto_t_ID: string[],
  store_mto_m_ID: string[],
  store_mto_o_ID: string[]
): FilteringActionTypes {
  return {
    type: STORE_FILTER_ID,
    store_incidentTypeId: store_incidentTypeId,
    store_areaId: store_areaId,
    store_systemId: store_systemId,
    store_triggering_reasonId: store_triggering_reasonId,
    store_sub_triggering_reasonId: store_sub_triggering_reasonId,
    store_operationId: store_operationId,
    store_sub_operationId: store_sub_operationId,
    store_equipmentId: store_equipmentId,
    storedDate: storedDate_Id,
    store_mto_t: store_mto_t_ID,
    store_mto_m: store_mto_m_ID,
    store_mto_o: store_mto_o_ID,
  };
}
