import { Bar } from "react-chartjs-2";

interface BarChartProps {
  data: any;
  name: string;
}

const BarChart: React.FC<BarChartProps> = ({ data, name }) => {
  return (
    <Bar
      data={data}
      options={{
        maintainAspectRatio: false,
        title: {
          display: true,
          text: name,
          fontSize: 20,
        },
        legend: {
          display: false,
        },
        scales: {
          xAxes: [
            {
              stacked: true,
              gridLines: {
                display: false,
              },
              offset: true,
            },
          ],
          yAxes: [
            {
              stacked: true,
              display: false,
              ticks: {
                display: false,
              },
            },
          ],
        },
      }}
    />
  );
};

export default BarChart;
