import React, { useCallback } from "react";
import { Container, Row } from "react-bootstrap";
import LanguageBar from "../languageBar/LanguageBar";
import SemiHeader from "../semiHeader/SemiHeader";
import "./Styles.css";
import { FiChevronLeft } from "react-icons/fi";
import { FaFileExport } from "react-icons/fa";
import Text from "../../@library/text/Text";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

interface HeaderProps {
  mainTitle: string;
  subTitle: string;
  systems: string | null;
  operations: string | null;
  area: string | null;
  triggeringReason: string | null;
  moduleName: string;
  downloadUrl: string;
}

interface BackMenuProps {
  downloadUrl: string;
}

const BackMenu: React.FC<BackMenuProps> = ({ downloadUrl }) => {
  const { t } = useTranslation();

  const activateDownload = useCallback(
    (e) => {
      e.preventDefault();

      fetch(downloadUrl)
        .then((res) => res.blob()) // Gets the response and returns it as a blob
        .then((blob) => {
          var filename = "download_" + ".pdf";
          var elem = window.document.createElement("a");
          elem.href = window.URL.createObjectURL(blob);
          elem.download = filename;
          document.body.appendChild(elem);
          elem.click();
          document.body.removeChild(elem);
        });
    },
    [downloadUrl]
  );

  return (
    <Row>
      <div className={"offset-md-2"}></div>
      <div className="col-md-8">
        <table className="table table-borderles">
          <tbody>
            <tr>
              <td className="headerBackMenu font-weight-bold">
                <Link
                  to={{
                    pathname: "/",
                  }}
                >
                  <FiChevronLeft color={"#000"} />
                  <Text text={t("back")} fontType="regular" />
                </Link>
              </td>
              <td className="headerBackMenu">
                {downloadUrl !== null ? (
                  <a href={"window.location"} onClick={activateDownload}>
                    <span style={{ float: "right" }}>
                      <Text text={t("download") + " "} fontType="regular" />
                      <FaFileExport color={"#A1AEB7"} />
                    </span>
                  </a>
                ) : null}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </Row>
  );
};

const Header: React.FC<HeaderProps> = ({
  mainTitle,
  subTitle,
  systems,
  operations,
  area,
  triggeringReason,
  moduleName,
  downloadUrl,
}) => {
  return (
    <>
      <LanguageBar />
      <Container
        id="headerId"
        className={
          moduleName !== "describe"
            ? "headerCustomConatinerWithPadding"
            : "headerCustomConatiner"
        }
        fluid
      >
        {moduleName === "describe" ? (
          <BackMenu downloadUrl={downloadUrl} />
        ) : null}

        <Row className="align-items-center">
          <div className={"offset-md-2"}></div>
          <div className="col-md-8">
            <h1 className="mainHeaderText">{mainTitle}</h1>
            <h2 className="semiMainHeaderText">{subTitle}</h2>
          </div>
        </Row>

        {moduleName === "describe" ? (
          <Row>
            <div className={"offset-md-2"}></div>
            <SemiHeader
              systems={systems}
              operations={operations}
              area={area}
              triggeringReason={triggeringReason}
            />
          </Row>
        ) : null}
      </Container>
    </>
  );
};

export default Header;
