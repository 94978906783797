import { gql } from "@apollo/client";

export const IncidentTypesQuery = gql`
  query IncidentTypesQuery {
    nb: incidentTypes(locales: nb) {
      id
      title
      incidents {
        id
      }
    }
    en: incidentTypes(locales: en) {
      id
      title
      incidents {
        id
      }
    }
  }
`;

export const SystemQuery = gql`
  query SystemQuery {
    en: systems(locales: en) {
      title
      id
      incidents {
        id
      }
    }

    nb: systems(locales: nb) {
      title
      id
      incidents {
        id
      }
    }
  }
`;

export const AreasQuery = gql`
  query SystemQuery {
    en: areas(locales: en) {
      title
      id
      incidents {
        id
      }
    }

    nb: areas(locales: nb) {
      title
      id
      incidents {
        id
      }
    }
  }
`;

export const TriggeringReasonsQuery = gql`
  query SystemQuery {
    en: triggeringReasons(locales: en) {
      title
      id
      incidents {
        id
      }
    }

    nb: triggeringReasons(locales: nb) {
      title
      id
      incidents {
        id
      }
    }
  }
`;

export const OperationsQuery = gql`
  query SystemQuery {
    en: operations(locales: en) {
      id
      title
      incidents {
        id
      }
    }

    nb: operations(locales: nb) {
      id
      title
      incidents {
        id
      }
    }
  }
`;

export const Operation2ndlevelQuery = gql`
  query SystemQuery {
    en: subOperations(locales: en) {
      id
      title
      incidents {
        id
      }
    }

    nb: subOperations(locales: nb) {
      id
      title
      incidents {
        id
      }
    }
  }
`;

export const equipmentInvolvedQuery = gql`
  query SystemQuery {
    en: equipments(locales: en) {
      id
      equipmentInvolved
      incidents {
        id
      }
    }

    nb: equipments(locales: nb) {
      id
      equipmentInvolved
      incidents {
        id
      }
    }
  }
`;

export const triggeringReasonCategoriesQuery = gql`
  query SystemQuery {
    en: triggeringReasonCategories(locales: en) {
      id
      title
      incidents {
        id
      }
    }

    nb: triggeringReasonCategories(locales: nb) {
      id
      title
      incidents {
        id
      }
    }
  }
`;
