import { gql } from "@apollo/client";

export const IncidentsFragment = gql`
  fragment IncidentsFragment on Incident {
    title
    id
    mtot
    mtoo
    mtom
    ingress {
      text
    }
    description {
      html
      text
    }
    incidentType {
      title
    }
    areas {
      title
    }
    system {
      title
    }
    operations {
      title
    }
    triggeringReason {
      title
    }
    incidentCauses {
      html
      text
    }
    learningPoints {
      html
      text
    }
    incidentNumber
    incidentDate
    triggeringReasonCategory {
      title
      id
    }
    subOperation {
      title
      id
    }
    equipment {
      equipmentInvolved
      id
    }
  }
`;
