import React from "react";
import Loader from "react-loader-spinner";
import { Col } from "react-bootstrap";

import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

const LoaderSpinner = () => {
  return (
    <Col
      style={{
        paddingTop: 60,
        width: "100%",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Loader type="ThreeDots" color="#6c757d" height={80} width={140} />
    </Col>
  );
};

export default LoaderSpinner;
