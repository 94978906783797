import React, { useState, useCallback, useEffect } from "react";
import { useSelector } from "react-redux";
import { Form, FormControl } from "react-bootstrap";

import { useDispatch } from "react-redux";
import { useLazyQuery } from "@apollo/client";

import {
  activeSearching,
  showFilterResultSmallDevice,
} from "../../@actions/SearchFilter";

import { IncidentsFragment } from "../../@services/SearchAndFilterQuery";

import { gql } from "@apollo/client";
import { storeIdGenerate } from "../checkBox/Methods";

import queryGenerate from "../../@services/QueryGenerate";

const SearchFilter = () => {
  const dispatch = useDispatch();

  const searchingItem = useSelector((state: any) => state.FILTER.searchingItem);
  const [inputValue, SetInputValue] = useState(searchingItem);
  const [initialAppStatus, SetInitialAppStatus] = useState(true);

  // Store ID

  const store_incidentTypeId = useSelector(
    (state: any) => state.FILTER.store_incidentTypeId
  );
  const store_areaId = useSelector((state: any) => state.FILTER.store_areaId);
  const store_systemId = useSelector(
    (state: any) => state.FILTER.store_systemId
  );
  const store_triggering_reasonId = useSelector(
    (state: any) => state.FILTER.store_triggering_reasonId
  );
  const store_sub_triggering_reasonId = useSelector(
    (state: any) => state.FILTER.store_sub_triggering_reasonId
  );
  const store_operationId = useSelector(
    (state: any) => state.FILTER.store_operationId
  );
  const store_sub_operationId = useSelector(
    (state: any) => state.FILTER.store_sub_operationId
  );
  const store_equipmentId = useSelector(
    (state: any) => state.FILTER.store_equipmentId
  );

  const storedDate = useSelector((state: any) => state.FILTER.storedDate);

  const store_mto_t = useSelector((state: any) => state.FILTER.store_mto_t);

  const store_mto_m = useSelector((state: any) => state.FILTER.store_mto_m);

  const store_mto_o = useSelector((state: any) => state.FILTER.store_mto_o);

  const load_query_status = useSelector(
    (state: any) => state.FILTER.load_query_status
  );

  const [queryCondition, setQueryCondition] = useState("");

  const MyQuery = gql`
    query MyQuery {
      en: incidents(locales: en ${queryCondition}) {
        ...IncidentsFragment
        attachmentNo{
          url
        }
        attachmentEn{
          url
        }
      }
      nb: incidents(locales: nb ${queryCondition}) {
        ...IncidentsFragment
        attachmentNo{
          url
        }
        attachmentEn{
          url
        }
      }
    }
    ${IncidentsFragment}
  `;

  const [loadGreetingData, { loading, data }] = useLazyQuery(MyQuery, {
    fetchPolicy: "no-cache",
  });

  const OnChangeText = useCallback(
    (info: any) => {
      info.preventDefault();

      SetInitialAppStatus(false);

      if (load_query_status === true && info.target.value !== undefined) {
        SetInputValue(info.target.value);
        let category = "search";
        let item_id = info.target.value;
        let Check = false;

        let return_id = storeIdGenerate(
          store_incidentTypeId,
          store_areaId,
          store_systemId,
          store_triggering_reasonId,
          store_sub_triggering_reasonId,
          store_operationId,
          store_sub_operationId,
          store_equipmentId,
          storedDate,
          store_mto_t,
          store_mto_m,
          store_mto_o,
          searchingItem,
          category,
          item_id,
          Check
        );

        let query_is: any = queryGenerate(return_id);

        setQueryCondition(query_is);

        loadGreetingData();
      }
    },
    [
      setQueryCondition,
      searchingItem,
      storedDate,
      store_mto_t,
      store_mto_m,
      store_mto_o,
      load_query_status,
      loadGreetingData,
      store_equipmentId,
      store_sub_operationId,
      store_operationId,
      store_sub_triggering_reasonId,
      store_triggering_reasonId,
      store_systemId,
      store_areaId,
      store_incidentTypeId,
      SetInputValue,
      SetInitialAppStatus,
    ]
  );

  useEffect(() => {
    if (initialAppStatus === false) {
      if (data !== undefined && loading === false) {
        dispatch(activeSearching(inputValue, data));
        SetInitialAppStatus(true);
      }
    }
  }, [inputValue, dispatch, initialAppStatus, loading, data]);

  useEffect(() => {
    if (searchingItem === "") {
      SetInputValue("");
    }
  }, [searchingItem]);

  const _handleKeyDown = (e: any) => {
    if (e.key === "Enter") {
      dispatch(showFilterResultSmallDevice());
    }
  };

  return (
    <Form
      style={{ width: "100%", paddingRight: 10, paddingLeft: 2 }}
      id="custom-search-form"
      className="form-search form-horizontal"
      onSubmit={OnChangeText}
    >
      <div className="input-append span12 customSearch93">
        <FormControl
          type="text"
          value={inputValue}
          placeholder="Search"
          onChange={OnChangeText}
          onKeyDown={_handleKeyDown}
        />
      </div>
    </Form>
  );
};

export default SearchFilter;
