export const filterCategoryIDCreator = (filteredIncident: any) => {
  try {
    let searching_wise_category: any = {
      incidentType: {},
      system: {},
      areas: {},
      triggeringReason: {},
      subTriggeringReason: {},
      operations: {},
      subOperations: {},
      equipment: {},
    };

    // searching wise available incidents category value set
    filteredIncident.map((e: any) => {
      let incidentType = e.incidentType.title;
      let system = e.system.title;
      let areas = e.areas[0].title;
      let triggeringReason = e.triggeringReason[0].title;
      let subTriggeringReason = e.triggeringReasonCategory.title;
      let operations = e.operations[0].title;
      let subOperations = e.subOperation.title;
      let equipment = e.equipment.equipmentInvolved;

      searching_wise_category.incidentType.hasOwnProperty(incidentType)
        ? (searching_wise_category.incidentType[incidentType] =
            searching_wise_category.incidentType[incidentType] + 1)
        : (searching_wise_category.incidentType[incidentType] = 1);

      searching_wise_category.system.hasOwnProperty(system)
        ? (searching_wise_category.system[system] =
            searching_wise_category.system[system] + 1)
        : (searching_wise_category.system[system] = 1);

      searching_wise_category.areas.hasOwnProperty(areas)
        ? (searching_wise_category.areas[areas] =
            searching_wise_category.areas[areas] + 1)
        : (searching_wise_category.areas[areas] = 1);

      searching_wise_category.triggeringReason.hasOwnProperty(triggeringReason)
        ? (searching_wise_category.triggeringReason[triggeringReason] =
            searching_wise_category.triggeringReason[triggeringReason] + 1)
        : (searching_wise_category.triggeringReason[triggeringReason] = 1);

      searching_wise_category.subTriggeringReason.hasOwnProperty(
        subTriggeringReason
      )
        ? (searching_wise_category.subTriggeringReason[subTriggeringReason] =
            searching_wise_category.subTriggeringReason[subTriggeringReason] +
            1)
        : (searching_wise_category.subTriggeringReason[
            subTriggeringReason
          ] = 1);

      searching_wise_category.operations.hasOwnProperty(operations)
        ? (searching_wise_category.operations[operations] =
            searching_wise_category.operations[operations] + 1)
        : (searching_wise_category.operations[operations] = 1);

      searching_wise_category.subOperations.hasOwnProperty(subOperations)
        ? (searching_wise_category.subOperations[subOperations] =
            searching_wise_category.subOperations[subOperations] + 1)
        : (searching_wise_category.subOperations[subOperations] = 1);

      searching_wise_category.equipment.hasOwnProperty(equipment)
        ? (searching_wise_category.equipment[equipment] =
            searching_wise_category.equipment[equipment] + 1)
        : (searching_wise_category.equipment[equipment] = 1);
    });

    return searching_wise_category;
  } catch (err) {
    console.log("Error in  filterCategoryIDCreator ", err);
  }
};
