import React from "react";
import { Switch, Route, BrowserRouter } from "react-router-dom";
import Main from "../@pages/main/Main";
import Describe from "../@pages/describe/Describe";

const Nav = () => {
  return (
    <>
      <BrowserRouter>
        <Switch>
          <Route exact path="/" render={() => <Main />} />
          <Route path="/describe/:id" component={Describe} />
        </Switch>
      </BrowserRouter>
    </>
  );
};

export default Nav;
