import { takeEvery } from "redux-saga/effects";
import {
  FILTERING,
  SEARCHING,
  INIT_APP_LOAD,
  RESET_FILTER,
} from "../@constants/Constants";

import { filterDataSave } from "./sagaActions/extendsFiltering";

import { initialAppData } from "./sagaActions/extendsInitAppData";

import { resetFilter } from "./sagaActions/resetFilter";

const rootSaga = function* (): any {
  yield takeEvery(INIT_APP_LOAD, initialAppData);
  yield takeEvery(FILTERING, filterDataSave);
  yield takeEvery(SEARCHING, filterDataSave);

  yield takeEvery(RESET_FILTER, resetFilter);
};

export default rootSaga;
