import React from "react";
import Route from "./@routes/Routes";

import { Provider } from "react-redux";
import store from "./@config/Store";

const App = () => {
  return (
    <Provider store={store}>
      <Route />
    </Provider>
  );
};

export default App;
