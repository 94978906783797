import { createStore, applyMiddleware } from "redux";

import logger from "redux-logger";
import createSagaMiddleware from "redux-saga";

import combineReducer from "../@reducers/index";
import rootSaga from "./Sagas";

const sagaMiddleware = createSagaMiddleware();

const middleware: any = [sagaMiddleware];

if (process.env.NODE_ENV === "development") {
  middleware.push(logger);
}

const Store = createStore(combineReducer, applyMiddleware(...middleware));

sagaMiddleware.run(rootSaga);

export default Store;
